<!-- 公共功能储存 -->
<template>
	<div id="app">
		<router-view></router-view>
		<!-- 点击添图片 调用image-dialog.vue组件 -->
		<image-dialog ref="imageDialog" :max="maxChooseImage"></image-dialog>
		<!-- 点击添规格 调用skus-dialog.vue组件 -->
		<skus-dialog ref="skusDialog"></skus-dialog>
	</div>
</template>

<script>
	import imageDialog from '@/components/image/image-dialog.vue'; // 引入 添加图片按钮弹出层-组件
	import skusDialog from '@/components/skus/skus-dialog.vue'; // 引入 添加sku按钮弹出层-组件
	export default {
		// 引入注册
		components:{
			imageDialog,
			skusDialog
		},
		name: 'app',
		// 依赖注入
		provide() {
			return {
				app: this
			}
		},
		data(){
			return{
				maxChooseImage: 9 //控制相册中,最多可以选择的图片数量
			}
		},
		// 生命周期-页面最先触发
		created() {
			// 初始化用户信息
			this.$store.commit('initUser')
			// 初始化后台菜单
			this.$store.commit('initNavBar')
		},
		methods:{
			// 选择图片
			chooseImage(callback,max = 9){
				this.maxChooseImage = max
				this.$refs.imageDialog.chooseImage(callback)
			},
			// 选择规格
			chooseSkus(callback){
				this.$refs.skusDialog.chooseSkus(callback)
			},
		}
	}
</script>

<style>
	::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, .1);
		border-radius: 0;
	}

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 10px;
		height: 10px;
	}

	::-webkit-scrollbar-thumb {
		cursor: pointer;
		border-radius: 5px;
		background: rgba(0, 0, 0, .25);
		transition: color .2s ease;
	}
</style>
