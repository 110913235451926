// 全局共公样式 调用时 方法内 优先执行
// 过滤器 按钮index转换成字符串
export default {
	data(){
		return{
			preUrl:"",
			
			// 分页
			page: {
				current: 1, // 当前分页
				sizes: [10, 20, 50, 100],
				size: 20, //默认显示10条
				total: 0, //默认总数为0
			},
			multipleSelection: [],
			loading:true
		}
	},
	filters: {
		numToString(value) {
			return value.toString();
		}
	},
	// 生命周期
	created(){
		this.getList()
	},
	// 计算属性
	computed: {
		// 选中记录id组成的数组
		ids() {
			return this.multipleSelection.map(item => {
				return item.id
			})
		}
	},
	// 生命周期监听页面
	methods:{
		// 判断loading用不用，防止与模态框冲突报错
		showLoading(){
			if(this.loading){
				this.layout.showLoading()
			}
		},
		// 判断loading用不用，防止与模态框冲突报错
		hideLoading(){
			if(this.loading){
				this.layout.hideLoading()
			}
		},
		
		// 处理列表结果 暴露在外面，方便单独修改
		getListResult(data){
			
		},
		// 获取请求列表分页的url暴露在外面，方便单独修改
		getListUrl(){
			return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}` // 拼接一下api链接 this获取page里的current
		},
		// 获取商品规格列表
		getList() {
			if(this.preUrl == '') return
			this.showLoading()
			let url = this.getListUrl()
			this.axios.get(url, { // 商品规格 api
				token: true
			}).then(res => {
				let data = res.data.data
				this.page.total = data.totalCount // 获取分页信息
				// console.log(data)
				this.getListResult(data) // 获取到的数据储存到getListResult(data)，方便外面调用修改
				this.hideLoading()
			}).catch(err => {
				this.hideLoading()
			})
		},
		// 添加/编辑
		addOrEdit(data,id = 0){ // id为0，表示添加操作
			// 修改
			this.showLoading() //显示loading动画
			let msg = id > 0 ? '修改' : '增加'
			let url = id > 0 ? '/admin/' + this.preUrl + '/' + id : '/admin/' + this.preUrl //修改规格 api
			this.axios.post(url, data, {
				token: true
			}).then(res => {
				this.$message({
					message: msg + '成功',
					type: 'success',
				});
				this.getList()
				this.hideLoading()
			}).catch(err => {
				this.hideLoading()
			})
		},
		// 批量删除url 自定义 针对不同链接形式
		deleteAllUrl(){
			return '/admin/' + this.preUrl + '/delete_all'
		},
		// 批量删除
		deleteAll() {
			console.log(this.ids)
			if (this.ids.length === 0) {
				return this.$message({
					message: '请先选中需要删除的信息',
					type: 'error'
				})
			}
			this.$confirm('是否要删除选中规格', '提示', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				// 批量删除
				this.showLoading()
				let url = this.deleteAllUrl()
				this.axios.post(url, { //批量删除 api
					ids: this.ids,
				}, {
					token: true
				}).then(res => {
					this.multipleSelection = []
					this.$message({
						message: '删除成功',
						type: 'success',
					});
					this.getList() //重新获取数据
					this.hideLoading()
				}).catch(err => {
					this.hideLoading()
				})
			})
		},
		// 修改状态
		changeStatus(item) {
			this.showLoading() //显示loading动画
			let status = item.status === 1 ? 0 : 1 // 判断状态，如果启动时是1，就变成0，否者就变成1
			let msg = status === 1 ? '启用' : '禁用'
			this.axios.post('/admin/' + this.preUrl + '/' + item.id + '/update_status', { //修改状态 api
				status: status
			}, {
				token: true
			}).then(res => {
				item.status = status
				this.$message({
					message: msg + '成功',
					type: 'success'
				})
				this.hideLoading()
			}).catch(err => {
				this.hideLoading()
			})
		},
		// 删除单个规格卡片
		deleteItem(item) {
			this.$confirm('是否要删除该规格', '提示', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				// 删除
				this.showLoading() //显示loading动画
				this.axios.post('/admin/' + this.preUrl + '/' + item.id + '/delete', {}, { //删除规格 api
					token: true
				}).then(res => {
					this.$message({
						message: '删除成功',
						type: 'success',
					});
					this.getList() //重新获取数据
					this.hideLoading()
				}).catch(err => {
					this.hideLoading()
				})
			})
		},
		// 多选触发判断
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 每页显示的条数
		handleSizeChange(val) {
			this.page.size = val
			this.getList()
			// console.log(`每页 ${val} 条`);
		},
		// 当前页码
		handleCurrentChange(val) {
			this.page.current = val
			this.getList()
		}
	}
}